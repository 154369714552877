<template>
     <div class="page-wrap">
      <div class="page-aside">
        <el-menu
        background-color="#1a2031"
        text-color="#fff"
        active-text-color="#4d85d9"
        :router="true"
        :default-active=$route.path
      >
          <el-menu-item v-for="(item,index) in navByAuth" :key="index" :index="item.path">{{item.title}}</el-menu-item>
      </el-menu>
       
      </div>
      <div class="page-main flex-column">
        <div ><router-view></router-view></div>
      </div>  
    </div> 

</template>
<script>
export default {
   name:'forecastapply',
   data(){
    return {
      subNav:[
        {title:'编辑报告',path:"/forecastapply/reportview",moduleCode:'bian_ji_bao_gao' },
        {title:'报告库',path:"/forecastapply/allreport",moduleCode:'bao_gao_ku' },
        {title:'预测结果统计',path:"/forecastapply/forecastStatistics",moduleCode:'wo_de_yu_ce_tong_ji' },
      ]
    }
   },
   computed:{
    navByAuth(){
      let module=(this.$store.state.loginRes.moduleList||[]).find((v)=>{return v.moduleCode=='yu_ce_ying_yong'}),
          arr=[]
      for(let item of this.subNav){
        if(module&&module.children.find((v)=>{return v.moduleCode==item.moduleCode})){
          arr.push(item)
        }
      }
      return arr
    }
  },
   methods:{
   
   }
}
</script>


